@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.works-with-container {
  &__title {
    margin-bottom: 1.5rem;
  }

  .device-logo-cont {
    display: inline-block;
  }

  .companies-subtitle {
    padding-top: 16px;
    padding-bottom: 10px;
  }

  .brand-logo-list {
    display: flex;
    flex-wrap: wrap;
    width: 66%;
  }
  .device-logo,
  .brand-logo {
    height: 200px;
    width: 200px;
    margin: 16px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  }

  .device-logo {
    height: 100px;
  }

  .brand-list-wrapper {
    display: flex;
    flex-flow: row wrap;
  }
}

@media screen and (max-width: $tablet) {
  .works-with-container {
    .devices-subtitle,
    .companies-subtitle {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .smart-device-logo-wrapper {
      display: flex;
      justify-content: center;
      .device-logo-cont {
        justify-content: center;
        img {
          width: 100px;
          height: 43px;
          margin: 16px;
        }
      }
    }
    .brand-list-wrapper {
      justify-content: center;
      .brand-logo-wrapper .brand-logo {
        width: 100px;
        height: 100px;
        margin: 16px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .works-with-container {
    .smart-device-logo-wrapper .device-logo-cont {
      img {
        width: 80px;
        height: 35px;
        margin: 8px;
      }
    }
    .brand-list-wrapper .brand-logo-wrapper .brand-logo {
      width: 80px;
      height: 80px;
      margin: 8px;
    }
  }
}
