@import '../../../styles/variables.scss';

//TODO: refactor, remove myUplink-policy classes once we stop using HTML strings for terms-of-service
.myUplink-policy {
  float: left;
  margin-right: 20px;
}
.myUplink-policy-link,
.myUplink-policy a {
  font-size: 1rem;
  color: $policyLinkColor;
  font-weight: 600;
  &:hover {
    color: $policyLinkColor;
  }
}

.tos-content {
  td {
    vertical-align: top;
    a {
      color: $policyLinkColor;
      font-weight: 600;
      text-decoration: underline;
      &:hover {
        color: $font-color-highlighted;
      }
    }
  }

  td:first-of-type {
    padding-right: 10px;
  }
}

h1#tos {
  margin-bottom: 0;
}
