@import '../../../../../styles/variables.scss';

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #52944f;
  --rdp-background-color: rgba(0, 0, 0, 0);
  --rdp-outline: rgba(0, 0, 0, 0);
  --rdp-outline-selected: rgba(0, 0, 0, 0);
  --rdp-month-color: #ffffff;
  --rdp-month-background-color: #5e5e5e;
  --rdp-day-range-background-color: #4bb945;
  --rdp-button-background-color: #7c99a2;
}

.rdp-month {
  padding: 15px;
  color: var(--rdp-month-color);
  background-color: var(--rdp-month-background-color);
}

.rdp-cell {
  button {
    margin: 0;
  }
}

.rdp-day {
  width: 50px;
  max-width: 50px;
}

.rdp-row {
  height: 50px;
}

.rdp-day_range_start,
.rdp-day_range_end {
  z-index: 1;
}

.rdp-day_range_end::before,
.rdp-day_range_start::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--rdp-day-range-background-color);
  position: absolute;
  left: 0;
  z-index: -1;
  border-radius: 50%;
}

.multi-range-calendar {
  position: absolute;
  top: 20px;
  left: -13.9px;
  z-index: 55;
}

.calendar__footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.calendar__footer p {
  margin: 0;
}

.calendar__footer {
  button {
    background-color: var(--rdp-button-background-color);
    border: none;
    font-size: $font-size-md;
    color: var(--rdp-month-color);
    width: 180px;
    padding: 10px 10px;
    border-radius: 8px;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
