@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;

  .fullHeight {
    .welcome-content {
      height: 100%;
    }
  }

  .welcome-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    overflow-y: auto; // scroll
    overflow-x: hidden; // scroll
    background: $welcomePageBackground;

    &:not(.aboutApp-page-height) {
      // This extends the white area on the right to its full height on larger screens.
      main {
        height: 100%;
      }
    }

    .footer-container {
      .footer-link-text,
      .footer-text {
        padding-left: 0;
      }
      .footer-links {
        padding-left: 48px;
        a {
          padding-left: 0;
        }
      }
      .footer-block {
        padding-right: 32px;
      }
    }

    .legal {
      padding-top: 82px;
      background-color: white;
      margin-bottom: -32px;
    }

    .logo {
      position: absolute;
      left: 48px;
      top: 32px;
      z-index: 9999;
      padding: 0;
    }

    .smart-device-logo-wrapper-signup {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .row {
      display: flex;
      gap: 10px;
      justify-content: center;
    }

    .row:first-child {
      width: 50%;
      display: flex;
      justify-content: center;
    }

    .row:last-child {
      width: 60%;
      display: flex;
      justify-content: center;
    }

    .lang-mob {
      display: none;
    }

    .slider-section {
      order: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58%;
      padding: 48px;
    }

    .login-section {
      order: 2;
      width: 42%;
      height: 100%;
      background-color: white;
      padding: 40px 30px 40px;
      display: flex;
      align-items: center;
      overflow-y: auto;
      flex-direction: column;

      .header {
        display: flex;
        text-align: center;
        margin-bottom: 24px;
        box-shadow: none;

        .navigation-item {
          margin: 0;
          border: 0;

          a,
          select {
            line-height: 1.1rem;
            font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 0.85rem;
            text-decoration: none;
            font-weight: 400;
            margin-right: 16px;
            color: $myuplink-text-color;
            display: inline-block;
            padding: 0;
            appearance: none;
          }

          #language-selector {
            margin: 0;
            padding: 0;
          }

          &.active {
            a {
              opacity: 1;
              font-weight: 500;
              text-decoration: underline;
              color: $button-blue;
            }
          }
        }

        .fa-globe-americas {
          display: inline-block;
          font-size: 0.85rem;
          line-height: 0.85rem;
          margin: 2px 0 0 0px;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        min-height: 480px;
        max-width: 370px;
      }
    }
  }

  &__contentWrapper {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__slideshow {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58%;
    height: 100vh;
    padding: 3em;
  }

  &__formWrapper {
    order: 2;
    width: 42%;
    height: 100vh;
    background-color: white;
    padding: 4em;
  }

  &__formWrapperContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__menuWrapper {
    width: calc(100% - 4rem);
    text-align: left;
    margin-bottom: 24px;
  }

  &__menuLink {
    @include label();
    font-size: 0.85rem;
    text-decoration: none;
    font-weight: 400;
    margin-right: 1rem;
    color: #7d7c7c;
    display: inline-block;

    &:first-of-kind {
      margin-left: 0;
    }

    &--active {
      opacity: 1;
      font-weight: 400;
      text-decoration: underline;
    }

    &:hover {
      color: #7d7c7c;
    }
  }

  .content.wide {
    background-color: #dfdfdf;
  }

  .narrow.login-section-accessible-background {
    background-color: #ffffff;
  }
}

.welcome {
  &-content {
    display: flex;
    padding: 0 32px 32px 0;
  }
}

.space-bottom {
  margin-bottom: 2rem;
}

#skiplink {
  background: #fff;

  a {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -10000px;
    top: auto;
    overflow: hidden;
    font-size: 20px;

    height: 44px;
    line-height: 40px;
    padding: 12px;

    &:focus {
      position: static;
      width: auto;
      height: auto;
    }
  }
}

.aboutApp-page {
  &-height.welcome-page {
    background-color: $font-color-footer;
  }

  &-header {
    max-width: 1525px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem 1rem 4rem;
    background-color: $worksWithBackground;

    .header-logo {
      width: 180px;
      & img {
        width: 150px;
      }
    }

    &-wrapper {
      background-color: $font-color-footer;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);
      position: relative;
    }
  }

  &-content {
    background-color: $worksWithBackground;

    & .page-content,
    & .legal-container {
      max-width: 1415px;
      width: 100%;
    }

    & .legal-container {
      margin: 0 auto;
    }
  }
}

@media all and (max-width: $largeDesktop_1380) {
  .inputComponent__field {
    min-width: auto;
  }
}

@media screen and (max-width: $tablet) {
  .form {
    margin-top: 32px;
  }

  .header {
    .navigation-list {
      a {
        margin-top: 8px;
      }
    }
  }

  .footer-container {
    margin-bottom: 0;
    .page-centered .footer-badges {
      flex-direction: row;
    }
  }

  .wrapper .fullHeight .welcome-content {
    height: auto;
  }

  .wrapper .welcome-page {
    .footer-container {
      .footer-links {
        padding-left: 0;
      }
      .footer-block {
        padding-right: 0;
      }
    }

    .login-section {
      order: 1;
      width: 100%;
      height: auto;
      padding: 32px 48px 48px;

      .header {
        width: 100%;
        .navigation-list {
          text-align: right;

          .navigation-item {
            a,
            #language-selector,
            .fa-globe-americas {
              font-size: 24px;
              line-height: 2.5rem;
            }
          }
        }
      }
    }
  }

  .wrapper .welcome-page:not(.aboutApp-page-height) {
    main {
      height: auto;
    }
  }

  .wrapper .welcome-page .slider-section {
    order: 2;
    width: 100%;
    height: auto;
    padding: 32px 48px 48px;
  }

  .wrapper .logo-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 32px;
    background-color: #ffffff;

    .logo {
      display: flex;
      position: relative;
      top: 0;
      padding: 0;
      left: 0;
      img {
        width: 150px;
      }
    }

    .customLanguageSelector {
      display: flex;

      #language-selector {
        font-size: 1.2rem;
        line-height: 1rem;
        padding: 12px;
        appearance: none;
      }
    }
  }

  .welcome-content {
    flex-direction: column;
    height: auto;
    padding: 0;
  }
}

@media screen and (max-width: $mobile) {
  .welcome {
    .content.wide.fullPage {
      padding: 0 2.5rem;
    }
  }

  .content {
    padding: 16px;
  }

  .wrapper {
    .welcome-page {
      .footer-container {
        .page-centered .footer-badges {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }

      .legal {
        padding-top: 0;
      }

      .lang-mob {
        display: flex;

        #language-selector {
          padding: 12px;
        }
      }

      .login-section {
        padding: 32px;
        padding-top: 0;

        .form {
          min-height: auto;
        }

        .header {
          padding: 0;

          .navigation-list {
            display: flex;
            flex-wrap: wrap;

            .navigation-item {
              a {
                font-size: large;
              }
            }
          }
        }
      }

      .slider-section {
        padding: 0;
      }

      .language-selector-li {
        display: none;
      }
    }
  }
}
