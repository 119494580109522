@import '../../../styles/media-queries.scss';

.languageSelector {
  display: flex;
  align-items: center;
  color: #454545;

  &__globeIcon {
    display: inline-block;
  }

  &__selectControl {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #454545;
    cursor: pointer;
    display: inline-block;
    float: none;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 2rem;
    width: auto;
    margin-right: 5px;
  }
}

@media screen and (max-width: $mobile) {
  .languageSelector {
    justify-content: center;
  }
}
