@import '../../../styles/variables.scss';

.privacy-policy {
  .title-links-container a {
    font-size: 1rem;
  }

  .text-link-container a,
  .title-links-container a {
    color: $policyLinkColor;
    font-weight: 600;
  }
  .text-link-container a:hover {
    color: $font-color-highlighted;
  }
}
