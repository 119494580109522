@import '../../../styles/media-queries.scss';
@import '../../../styles/variables.scss';

.legal-container {
  //TODO: refactor once we stop using HTML strings for terms-of-service
  .myUplink-policy {
    float: left;
    margin-right: 20px;
  }
  .myUplink-policy-link,
  a {
    color: $policyLinkColor;
    font-weight: 600;
  }
  .myUplinkPro-policy-link {
    color: $font-color-timeStamp;
    font-weight: normal;
    text-decoration: none;
  }
  .myUplink-policy-link,
  .myUplinkPro-policy-link {
    font-size: 1rem;
  }
  @media only screen and (max-width: $smallDesktop) {
    margin: 3%;
  }

  tr {
    td:first-of-type {
      padding-right: 10px;
    }

    td {
      vertical-align: top;
      padding: 5px;
    }
  }
}
