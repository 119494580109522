@import '../../../../../../styles/media-queries.scss';

.modal-open .modal {
  overflow-x: auto !important;
}
.UserDetail {
  font-weight: 400;
  font-size: 17px;

  .UserDetailTitle {
    margin-top: -0.5rem;
    font-size: 30px;
    font-weight: 600;
  }

  &__heading {
    height: 56px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }

  &__description {
    height: 48px;
    font-size: 18px;
    letter-spacing: 0.01px;
    line-height: 24px;
    margin-top: 22px;
    text-align: left;
  }

  &__content-wrapper {
    width: 50vw;
    border-radius: 10px;
    background-color: #f2f2f2;
    padding: 40px 31px 31px 31px;
  }

  &__row-wrapper {
    box-sizing: border-box;
    border: 1px solid #d2d2d2;
    display: flex;
    padding: 5px;
    justify-content: space-between;
    p {
      text-align: left;
    }
  }

  &__key {
    margin-left: 10px;
  }

  &__value {
    width: 50%;
  }

  &__button-wrapper {
    margin-top: 13.75rem;
    align-self: center;
    justify-content: center;
    display: grid;
    button {
      padding: 16px 40px;
      min-width: 200px;
      border-radius: 46px;
      width: fit-content;
    }
  }

  .userDetail__next-button {
    background: linear-gradient(180deg, #ff5722 0%, #e84e1d 100%);
  }

  &__back-button {
    margin-top: 10px;
    background-color: #828282;
  }

  .popup-modal {
    width: fit-content;
    border-radius: 10px;
    background-color: #dfdfdf;
    padding: 44px;
    margin-right: 0px;
  }
}

@media (max-width: $tablet) {
  .popup-modal {
    width: inherit !important;
    padding: 44px 20px !important;
  }
  .UserDetail {
    &__row-wrapper {
      justify-content: space-between;
    }
    &__content-wrapper {
      width: inherit;
    }
    &__checkbox-wrapper {
      width: inherit;
    }
    &__value {
      width: 50%;
      word-break: break-word;
    }
  }
}
