.table {
  border: 1px solid #bfbfbf;

  &--header {
    background: linear-gradient(#fcfcfc, #eee);
    font-weight: 700;
    display: flex;
    overflow: hidden;

    .table--item {
      padding: 1rem 2rem;
    }
  }

  &--content {
    display: flex;

    &:not(:last-child) {
      border-bottom: 0.5px solid #bbb;
    }
  }

  &--item {
    padding: 2rem;

    &:nth-child(1) {
      width: 33.33%;
    }

    &:nth-child(2) {
      width: 33.33%;
    }

    &:nth-child(3) {
      width: 33.33%;
      position: relative;
    }
  }

  &--remove-headCell {
    text-align: center;
  }
}

.permission-description {
  margin-bottom: 1.5rem;
}
