.storage-error-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff3cd;
  color: #664d03;
  padding: 12px 20px;
  border-top: 1px solid #ffecb5;
  z-index: 1050;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.3s ease-out;

  &.critical {
    background-color: #f8d7da;
    color: #842029;
    border-top-color: #f5c2c7;
  }

  &__content {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__icon {
    font-size: 20px;
    margin-right: 12px;
    display: flex;
    align-items: center;
  }

  &__message {
    font-size: 14px;
    line-height: 1.4;
  }

  &__action {
    background: transparent;
    border: 1px solid currentColor;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 16px;
    color: inherit;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
